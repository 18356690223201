<template>
    <div class="login-status">

    </div>
</template>

<script>
    import {GetPartnerAuthDataResult } from '@/request/api';
    import {mapActions} from 'vuex';
    import { Loading } from 'element-ui';
    export default {
        name: '',
        components: {
        },
        data() {
            return {
                code:'',
                state:'',
                message: "會員登入中..."
            }
        },
        methods: {
            ...mapActions(['getCartNum']),
            getLoginAuthDataResult(loginType) {
                if (!this.code || this.code==undefined || this.code=="") {
                    this.$message.error('抱歉,服務器開小差了,請稍後再試');
                    setTimeout(() => {
                        this.$router.push('/');
                        // this.$router.push('/home');
                    }, 1500);
                    return;
                }
                let loadingInstance = Loading.service({ fullscreen: true });
                let _this=this;
                GetPartnerAuthDataResult({
                    loginType: loginType,
                    code: this.code,
                    state: this.state,
                }).then(res => {
                    if (res.code == 200) {
                        _this.$store.commit('SET_USER', res.data);
                        _this.$store.commit('SET_LOGINED', true);

                        var auth = {"token": res.data.token, "nickName": res.data.nickName, "avatar": res.data.avatar};
                        localStorage.setItem("magor3-auth", JSON.stringify(auth));
                        _this.$message.success(res.message);

                        _this.getCartNum();
                        if (_this.$route.query.redirect) { //如果存在参数
                            let redirect = _this.$route.query.redirect;
                            _this.$router.push(redirect)//则跳转至进入登录頁前的路由
                        } else {
                            _this.$router.push('/')//否则跳转至首頁
                        }
                    } else {
                        //this.message = "登入出現異常,請稍後再試...";
                        _this.$message.error(res.message);
                    }
                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                }).catch(e => {
                    console.log("error:",e);
                    _this.$message.error(e);
                    _this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                });
            }
        },
        mounted() {
            if (this.$route.params.type) {
                this.getLoginAuthDataResult(this.$route.params.type);
            }
        }
        ,created() {
            this.code=this.$route.query.code;
            this.state=this.$route.query.state;
        }
    }
</script>
<style lang="less" scoped>
.login-status{
    padding-top: 50%;
}
</style>
